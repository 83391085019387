<template>
  <base-view id="current-video">
    <div
      class="flex flex-col lg:flex-row lg:justify-center w-full lg:px-24 my-0 max-w-7xl"
    >
      <div class="w-full lg:w-1/3">
        <quick-tip-sub-nav
          title="Elevate Training"
          :links="links"
          @change-video="receiveVideo"
        />
      </div>
      <div
        class="flex flex-row mb-6 overflow-auto lg:flex-col w-full lg:w-2/3 lg:pl-12"
      >
        <div class="pt-0 lg:pt-16 pb-16 w-full h-full">
          <div class="w-full h-full flex flex-col items-center justify-center">
            <h1 class="mb-12 text-4xl ml-4 text-center font-semibold">
              {{ links[current - 1].title }}
            </h1>
            <div v-for="item in links" :key="item.id" class="w-full">
              <div v-if="item.id == current" class="w-full px-4">
                <div v-if="links[current - 1].type == 'youtube'">
                  <vue-plyr>
                    <div class="plyr__video-embed">
                      <iframe
                        :src="
                          `https://www.youtube.com/embed/${
                            links[current - 1].video
                          }?iv_load_policy=3&modestbranding=1&playsinline=1&showinfo=0&rel=0&enablejsapi=1&controls=0`
                        "
                        allowfullscreen
                        allowtransparency
                        allow="autoplay"
                      ></iframe>
                    </div>
                  </vue-plyr>
                </div>
                <div v-else-if="links[current - 1].type == 'demo'">
                  <p class="mb-4 text-md text-neutral-700">
                    This will take you through a tour of the new elevate
                    software. Click the button below and it will open up in a
                    new tab.
                  </p>
                  <a
                    href="https://elevate.navattic.com/6k540tub"
                    target="_blank"
                    class="inline-flex items-center gap-x-2 rounded-md hover:bg-primary-800 bg-primary-700 px-3.5 py-2.5 text-md font-semibold text-white shadow-sm"
                  >
                    Elevate Demo
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-box-arrow-up-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"
                      />
                      <path
                        fill-rule="evenodd"
                        d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </base-view>
</template>

<script>
import BaseView from "@/components/layout/BaseView.vue";
import QuickTipSubNav from "@/components/layout/QuickTipSubNav.vue";

export default {
  components: {
    BaseView,
    QuickTipSubNav
  },
  data() {
    return {
      current: "1",
      links: [
        {
          title: "Check Balance",
          video: "oNiWTOrlBww",
          id: "1",
          type: "youtube"
        },
        {
          title: "Submit A Claim",
          video: "XOQtc1rzbyI",
          id: "2",
          type: "youtube"
        },
        {
          title: "How To Register",
          video: "44AdnOh0kRs",
          id: "3",
          type: "youtube"
        },
        {
          title: "Use Your card",
          video: "W3MoBg5nlTM",
          id: "4",
          type: "youtube"
        },
        {
          title: "Uploading Documentation",
          video: "EHaU1cvbDgc",
          id: "5",
          type: "youtube"
        },
        {
          title: "Change Payment Method",
          video: "YSAa54ORB5E",
          id: "6",
          type: "youtube"
        },
        {
          title: "Elevate Demo",
          video: "xxxxxxx",
          id: "7",
          type: "demo"
        }
      ]
    };
  },
  methods: {
    receiveVideo(video) {
      this.current = video;
    }
  }
};
</script>
