<template>
  <base-view id="elevate-training">
    <view-header title="Elevate Training" image="bg-login.jpg">
      Elevate is the platform that employers use to access and edit information
      about their employees’ flexible health benefits. This platform allows
      manipulation of benefits cards, adding dependents, reimbursement methods,
      and more.
    </view-header>
    <current-video />
  </base-view>
</template>

<script>
import BaseView from "@/components/layout/BaseView.vue";
import ViewHeader from "@/components/layout/ViewHeader.vue";
import CurrentVideo from "./QuickTipVideos/ElevateVideos.vue";

export default {
  components: {
    BaseView,
    ViewHeader,
    CurrentVideo
  }
};
</script>
